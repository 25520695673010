import React from 'react';
import { shape, string } from 'prop-types';

import classnames from 'classnames';
import { Image } from 'nordic/image';
import get from 'lodash/get';

import Link from '../../link';
import { useStaticProps } from '../../../components/context/static-props';
import { trackEvent } from '../../../lib/melidata';
import { withComponentHandler } from '../../../hocs/with-component-handler';

const namespace = 'ui-search-header--official-store';
/**
 * @typedef {Object} BackgroundProps
 * @property {Object} pictures - Las imágenes asociadas al fondo.
 * @property {string} pictures.2x - La URL de la imagen en resolución 2x.
 */

/**
 * @typedef {Object} OfficialStoreHeaderProps
 * @property {string} className - Clases CSS adicionales para el componente.
 * @property {Object} logo - Las propiedades del logo.
 * @property {string} logo.src - La URL de la imagen del logo.
 * @property {string} logo.alt - El texto alternativo para el logo.
 * @property {string} title - El título del encabezado.
 * @property {BackgroundProps} background - Las propiedades del fondo del encabezado.
 * @property {string} url - La URL asociada al encabezado.
 */

/**
 * Componente que renderiza el encabezado de una tienda oficial con logo, título, fondo y enlace.
 *
 * @param {OfficialStoreHeaderProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el encabezado de la tienda oficial.
 */
const OfficialStoreHeader = ({ className = null, logo, title, background, url }) => {
  const { lowEnd } = useStaticProps();

  return (
    <div className={classnames(className, namespace)}>
      <Image
        src={get(background, 'pictures.2x')}
        preload
        lazyload="off"
        className={`${namespace}__banner`}
        lowEnd={lowEnd}
        alt="official store"
      />
      <div className={`${namespace}__wrapper`}>
        <Link
          className={`${namespace}__link`}
          href={url}
          title={title}
          tabIndex="-1"
          isInternal={false}
          onClick={() =>
            trackEvent({ path: '/search/official_store_logo/click', event_data: { store: title, url }, clean: true })
          }
        >
          <Image
            alt={title}
            className={`${namespace}__image`}
            src={get(logo, 'pictures.2x')}
            lowEnd={lowEnd}
            preload
            lazyload="off"
          />
        </Link>
      </div>
    </div>
  );
};

OfficialStoreHeader.propTypes = {
  background: shape({
    pictures: shape({
      '2x': string.isRequired,
    }).isRequired,
  }).isRequired,
  className: string,
  logo: shape({
    pictures: shape({
      '2x': string.isRequired,
    }).isRequired,
  }).isRequired,
  title: string.isRequired,
  url: string.isRequired,
};

export default withComponentHandler(OfficialStoreHeader, { componentName: 'OfficialStoreHeaderDesktop' });
